import { usePrivacyPolicy } from "hooks/usePrivacyPolicy";

function Eula() {
  usePrivacyPolicy();

  return (
    <div
      // @ts-ignore
      name="termly-embed"
      data-id="b5316ba5-8a99-466b-b576-a6c392fb5383"
    ></div>
  );
}

export default Eula;
