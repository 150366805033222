import React, { useContext } from "react";
import FbxLogo from "pages/Home/FbxLogo";
import useWindowDimensions from "hooks/useWindowDimensions";
import useIsVisible from "hooks/useIsVisible";

import CheckSvg from "./assets/section4/check.svg";
import CheckGreySvg from "./assets/section4/check-grey.svg";
import StarSvg from "./assets/star.svg";
import RudderSvg from "./assets/rudder.svg";

import "./styles.scss";
import { HomeContext } from "contexts";
import { isAndroid, isIOS } from "react-device-detect";
import { useTranslation } from "react-i18next";

function Section4() {
  const { t } = useTranslation();
  const { elementRef } = useIsVisible("Price");
  const { width } = useWindowDimensions();
  const isMobile = width <= 840 || isIOS || isAndroid;
  const { isSomeMenuActive } = useContext(HomeContext);

  const islandWidth = isMobile ? 167 : 259;
  const islandHeight = isMobile ? 137 : 212;
  const data = [
    {
      title: "Traffic Camera Radar System",
      first: true,
      second: false,
      third: false,
    },
    {
      title: "Photo and Video Records of Fines",
      first: true,
      second: false,
      third: false,
    },
    {
      title: "Fine Locations on the Map",
      first: true,
      second: false,
      third: false,
    },
    {
      title: "Speeding Fine Details",
      first: true,
      second: false,
      third: false,
    },
    {
      title: "Fine Payment Reminders",
      first: true,
      second: true,
      third: false,
    },
    {
      title: "Technical Inspection Date Alerts",
      first: true,
      second: true,
      third: false,
    },
    {
      title: "Filtering Non-Speeding Fines",
      first: true,
      second: true,
      third: false,
    },
    {
      title: "Automatic Fine Collection",
      first: true,
      second: true,
      third: false,
    },
    {
      title: "Explanation of Fine Clauses",
      first: true,
      second: true,
      third: false,
    },
    {
      title: "100% Fine Payment Guarantee",
      first: true,
      second: true,
      third: true,
    },
    {
      title: "Fuel Prices",
      first: true,
      second: true,
      third: true,
    },
    {
      title: "Summary of Paid Fines",
      first: true,
      second: true,
      third: true,
    },
    { title: "Payment History", first: true, second: true, third: true },
    {
      title: "Easy Fine Payments",
      first: true,
      second: true,
      third: true,
    },
    {
      title: "Quick Access to Fine Websites",
      first: false,
      second: false,
      third: true,
    },
  ];

  const data2 = [
    {
      title: "Fines Issued by Tbilisi City Hall",
      first: false,
      second: false,
      third: true,
    },
    {
      title: "Tow Truck Alerts",
      first: false,
      second: false,
      third: true,
    },
  ];

  return (
    <div id="Price" className="section4 mb128 w100 mw1400">
      <FbxLogo />
      <h2 className="medium70-text mb16 text-align-center" ref={elementRef}>
        {t("Save with Borbalo")}
      </h2>
      <h3 className="medium50-text mb16 text-align-center">
        {t("Affordable Options")}
      </h3>
      <p className="regular24-text text-align-center mb128">
        {t("Starting from 1₾ per Month")}
      </p>
      <div className="gap32 column mb128" style={{ position: "relative" }}>
        <div
          id="sticky"
          className="mw1400 grid sticky"
          style={{
            zIndex: isMobile ? (isSomeMenuActive ? 1 : 3) : 0,
          }}
        >
          {!isMobile && <div />}
          <div className="column ai-center gap8">
            <div className="row">
              <img src={StarSvg} width="26px" height="26px" alt="star" />
              <p className="medium24-text mb8">&nbsp;{t("Full")}</p>
            </div>
            <p className="regular24-text text-align-center">
              {t("2₾ per Month")}
            </p>
          </div>
          <div className="column ai-center gap8">
            <div className="row">
              <img src={RudderSvg} width="26px" height="26px" alt="star" />
              <p className="medium24-text mb8">&nbsp;{t("Basic")}</p>
            </div>
            <p className="regular24-text text-align-center">
              {t("1₾ per Month")}
            </p>
          </div>
          <div className="column ai-center gap8">
            <div className="row">
              <p className="medium24-text mb8">{t("Restricted")}</p>
            </div>
            <p className="regular24-text text-align-center">{t("Free")}</p>
          </div>
        </div>
        <div className="mw1400 grid">
          {data.map(item => (
            <React.Fragment key={item.title}>
              <p className="medium26-text section4-text">{t(item.title)}</p>
              <div className="check-wrapper">
                {item.first && (
                  <img
                    src={CheckSvg}
                    width="20px"
                    height="24px"
                    alt="round-logo"
                  />
                )}
              </div>
              <div className="check-wrapper">
                {item.second && (
                  <img
                    src={CheckGreySvg}
                    width="20px"
                    height="24px"
                    alt="round-logo"
                  />
                )}
              </div>
              <div className="check-wrapper">
                {item.third && (
                  <img
                    src={CheckGreySvg}
                    width="20px"
                    height="24px"
                    alt="round-logo"
                  />
                )}
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
      <div className="w100 mw1400">
        <h3 className="medium38-text mb32 ">{t("Included Agencies")}</h3>
        <div className="row flex-wrap jc-space-between gap32 mb64">
          <div className="column ai-center">
            <img
              src={require("./assets/section4/island1.png")}
              width={islandWidth}
              height={islandHeight}
              alt="island-1"
              className="mb32"
            />
            <p className="medium26-text text-align-center">
              {t("Ministry of Internal Affairs")}
            </p>
          </div>
          <div className="column ai-center">
            <img
              src={require("./assets/section4/island2.png")}
              width={islandWidth}
              height={islandHeight}
              alt="island-2"
              className="mb32"
            />
            <p className="medium26-text text-align-center">{t("Kutaisi")}</p>
          </div>
          <div className="column ai-center">
            <img
              src={require("./assets/section4/island3.png")}
              width={islandWidth}
              height={islandHeight}
              alt="island-3"
              className="mb32"
            />
            <p className="medium26-text text-align-center">{t("Gori")}</p>
          </div>
        </div>

        <h3 className="medium38-text mb32">
          {t("Some Agencies Require Additional Fee")}
        </h3>
        <div className="row flex-wrap jc-space-between gap32 mb64">
          <div className="column ai-center">
            <img
              src={require("./assets/section4/island4.png")}
              width={islandWidth}
              height={islandHeight}
              alt="island-4"
              className="mb32"
            />
            <p className="medium26-text text-align-center">
              {t("Tbilisi City Hall")}
            </p>
          </div>
        </div>
      </div>
      <div className="mw1400 grid2">
        <div className="mb32" />
        {isMobile && <div className="mb32" />}
        <div className="column ai-center gap8 mb32">
          <div className="row">
            <p className="medium24-text mb8">{t("Tbilisi")}</p>
          </div>
          <p className="regular24-text text-align-center">
            {t("+ 1₾ per Month")}
          </p>
        </div>
        {data2.map(item => (
          <React.Fragment key={item.title}>
            <p className="medium26-text section4-text">{t(item.title)}</p>
            {isMobile && (
              <>
                <div className="check-wrapper" />
                <div className="check-wrapper" />
              </>
            )}
            <div className="check-wrapper">
              {item.third && (
                <img
                  src={CheckSvg}
                  width="20px"
                  height="24px"
                  alt="round-logo"
                />
              )}
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}

export default Section4;
