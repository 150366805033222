import "./styles.scss";
import ArrowSvg from "./assets/arrow.svg";
import { cards1, cards2, cards3, cards4 } from "pages/Home/cards";
import useWindowDimensions from "hooks/useWindowDimensions";
import clsx from "clsx";
import { useRef } from "react";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";

import { Mousewheel } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import useIsVisible from "hooks/useIsVisible";
import { useTranslation } from "react-i18next";

type Card = {
  title: string;
  greyTitile: string;
  cards: any[];
};

document.body.style.overscrollBehaviorX = "none";

const data: Card[] = [
  {
    title: "Fines",
    greyTitile: ". All the Country’s Fines in One Place",
    cards: cards1,
  },
  {
    title: "Services",
    greyTitile: ". Everything Your Car Needs",
    cards: cards2,
  },
  {
    title: "Standards",
    greyTitile: ". The Benchmark Nationwide",
    cards: cards3,
  },
  {
    title: "From Trusted Sources",
    greyTitile: ". Official, Reliable Information",
    cards: cards4,
  },
];

let isCanChangeSlide = true;

const CardsComponent = ({ item }: { item: Card }) => {
  const { t } = useTranslation();
  const { elementRef } = useIsVisible("Features");
  const ref = useRef<SwiperRef>(null);

  const { width } = useWindowDimensions();

  const handleScroll = (e: any) => {
    if (e.deltaY > 3 || e.deltaY < -3) {
      return;
    }

    if (e.deltaX > 3 && isCanChangeSlide) {
      ref.current?.swiper?.slideNext();
    }
    if (e.deltaX < -2 && isCanChangeSlide) {
      ref.current?.swiper?.slidePrev();
    }
  };

  return (
    <div
      className="column gap32 mb128"
      onWheel={width < 640 ? undefined : handleScroll}
    >
      <h2 className="medium50-text mh64">
        {t(item.title)}
        <span className="color-grey" id="Features" ref={elementRef}>
          {t(item.greyTitile)}
        </span>
      </h2>
      <Swiper
        ref={ref}
        slidesPerView={"auto"}
        spaceBetween={10}
        parallax={true}
        modules={[Mousewheel]}
        pagination={{
          clickable: true,
        }}
        className="mySwiper"
      >
        {item.cards.map(card => (
          <SwiperSlide key={card.title}>
            <div
              className="card-wrapper"
              style={{
                backgroundImage: `url(${card.backgroundIcon})`,
              }}
            >
              <div>
                <div className="row gap16 ai-center mb16">
                  <div
                    className="small-icon-wrapper"
                    style={{ backgroundColor: card.backgroundIconColor }}
                  >
                    <img src={card.icon} alt="icon" />
                  </div>
                  <h3
                    className="medium32-text flex1 relative"
                    style={{
                      color: card.textColor,
                      fontSize: card.titleFontSize
                        ? card.titleFontSize - (width > 640 ? 0 : 9)
                        : undefined,
                      lineHeight: `${
                        card.titleFontSize
                          ? card.titleFontSize - (width > 640 ? 0 : 9) + 4
                          : 38
                      }px`,
                    }}
                  >
                    {t(card.title)}
                    {!!card.smallTitle && (
                      <span className="small-title medium16-text color-whiteGrey">
                        {t(card.smallTitle)}
                      </span>
                    )}
                  </h3>
                </div>
                <p
                  className="regular20-text mb16"
                  style={{
                    color: card.textColor,
                  }}
                >
                  {t(card.description)}
                </p>
                <div className="row">
                  <a
                    href={"https://app.borbalo.ge/redirecttoapp"}
                    className={clsx(
                      // {
                      //   "bg-black": card.isButtonBlack,
                      //   "bg-white": !card.isButtonBlack,
                      //   "color-black": !card.isButtonBlack,
                      //   "color-white": card.isButtonBlack,
                      // },
                      "button",
                    )}
                  >
                    {/* {card.buttonText} */}
                  </a>
                </div>
              </div>
              <div>
                <div className="row ai-center gap8 mb4">
                  <img
                    className="footer-icon"
                    src={card.footerIcon}
                    alt="footer-icon"
                  />
                  <div
                    className="medium16-text"
                    style={{
                      color: card.textFooterColor,
                    }}
                  >
                    {t(card.footerTitle)}
                  </div>
                </div>
                <div
                  className="regular18-text"
                  style={{
                    color: card.textFooterColor,
                  }}
                >
                  {t(card.footerText)}
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="arrow-buttons">
        <div
          className="arrow"
          onClick={() => {
            ref.current?.swiper?.slidePrev();
          }}
        >
          <img src={ArrowSvg} width="11.916" height="19.777" alt="arrow" />
        </div>
        <div
          className="arrow-right arrow"
          onClick={() => {
            ref.current?.swiper?.slideNext();
          }}
        >
          <img src={ArrowSvg} width="11.916" height="19.777" alt="arrow" />
        </div>
      </div>
    </div>
  );
};

function CardsSection() {
  return (
    <div className="second-section">
      {data.map(item => (
        <CardsComponent key={item.title} item={item} />
      ))}
    </div>
  );
}

export default CardsSection;
