import { useEffect } from "react";

export function useGenericPartnersPrivacyPolicy() {
  useEffect(() => {
    const script = document.createElement("script");
    const style = document.createElement("style");

    script.text = `
      (function(d, s, id) {
      var js, tjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = "https://app.termly.io/embed-policy.min.js";
      tjs.parentNode.insertBefore(js, tjs);
      }(document, 'script', 'termly-jssdk'));
    `;
    script.type = "text/javascript";
    script.async = false;
    style.innerHTML = `
      * {
        box-sizing: initial;
        padding: initial;
        margin: initial;
      }

      a {
        color: initial;
        text-decoration: initial;
      }

      body {
        margin: 8px;
        background-color: initial;
        min-width: unset;
      }
    `;

    document.body.appendChild(style);
    document.head.appendChild(script);
  }, []);
}
