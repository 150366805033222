import Section2 from "pages/Other/Section2";
import Section3 from "pages/Other/Section3";
import "./styles.scss";
import "assets/styles/globals.scss";
import Section1 from "pages/Other/Section1";

function Other() {
  return (
    <div className="other-page-content">
      <Section1 />
      <Section2 />
      <Section3 />
    </div>
  );
}

export default Other;
