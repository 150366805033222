import { useContext, useEffect, useState } from "react";
import ScrollIntoView from "react-scroll-into-view";
import Drawer from "react-modern-drawer";
import { useLocation } from "react-router-dom";
import useWindowDimensions from "hooks/useWindowDimensions";
import clsx from "clsx";
import "react-modern-drawer/dist/index.css";
import { HomeContext } from "contexts";
import { useTranslation } from "react-i18next";

export const NavigationHeader = () => {
  const { t } = useTranslation();
  const { activeId, changeActiveId, changeIsSomeMenuActive } =
    useContext(HomeContext);
  const { width } = useWindowDimensions();
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const toggleDrawer = () => {
    setIsOpen(prevState => !prevState);
  };

  useEffect(() => {
    changeIsSomeMenuActive(isOpen);
  }, [isOpen]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    changeActiveId("");
    setIsOpen(false);
  }, [location]);

  const buttons: {
    [key: string]: string[];
  } = {
    "/other": ["Team", "History", "Download"],
    "/": ["Features", "Price", "Download"],
  };

  const onClick = (text?: string) => {
    if (width < 640 && !isOpen) {
      return setIsOpen(true);
    }

    setIsOpen(false);

    if (text) {
      // changeActiveId(text);
    }
  };

  return (
    <>
      <div className="nav-secondary-wrapper">
        <nav className="nav">
          <div className="medium24-text color-black">{t("Borbalo")}</div>
          <div className="row gap16">
            {width < 640 ? (
              <div
                className={clsx(
                  {
                    "bg-main": activeId !== "",
                  },
                  "button regular18-text  br-30",
                )}
                onClick={() => onClick()}
              >
                {t(activeId || buttons[location.pathname][0])}
              </div>
            ) : (
              buttons[location.pathname].map((text, index) => (
                <ScrollIntoView selector={`#${text}`} key={text}>
                  <div
                    key={text}
                    className={clsx(
                      {
                        "bg-main": activeId === text,
                      },
                      "button regular18-text  br-30",
                    )}
                    onClick={() => onClick(text)}
                  >
                    {t(text)}
                  </div>
                </ScrollIntoView>
              ))
            )}
          </div>
        </nav>
      </div>
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="top"
        className="navigation-drawer"
      >
        <div className="drawer-background drawer-background-navigation">
          {buttons[location.pathname].map(text => (
            <ScrollIntoView selector={`#${text}`} key={text}>
              <button
                className="drawer-item drawer-item-center button w100"
                onClick={() => onClick(text)}
              >
                <div
                  key={text}
                  className={clsx(
                    {
                      "color-main": activeId === text,
                    },
                    "button regular18-text  br-30",
                  )}
                >
                  {t(text)}
                </div>
              </button>
            </ScrollIntoView>
          ))}
        </div>
      </Drawer>
    </>
  );
};
