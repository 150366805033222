import CardsSection from "pages/Home/CardsSection";
import Section1 from "pages/Home/Section1";
import Section3 from "pages/Home/Section3";
import Section5 from "pages/Home/Section5";
import Section4 from "pages/Home/Section4";
import "assets/styles/globals.scss";
import "./styles.scss";
import { useTranslation } from "react-i18next";

function Home() {
  const { t } = useTranslation();
  return (
    <div className="content">
      <Section1 />
      <CardsSection />
      <Section3 />
      <Section4 />
      <Section5 />
      <p className="regular14-text color-grey last-section-text">
        {t("* Definition 1")}
      </p>
    </div>
  );
}

export default Home;
