import { Canvas, useFrame } from "@react-three/fiber";
import { useEffect, useRef, useState } from "react";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { OrbitControls } from "@react-three/drei";
import { modelFbx } from "./model";
import { MathUtils } from "three";

const model = modelFbx;

function Box() {
  const [obj, setObj] = useState(null);
  const ref = useRef(null);
  const ref2 = useRef(null);

  useFrame((state, delta) => {
    if (ref.current && ref2.current) {
      ref.current.rotation.y -= delta / 1;
    }
  });

  const loadFbx = (md, index) => {
    const objectLoader = new FBXLoader();
    const group = objectLoader.parse(md);
    group.traverse(function (node) {
      if (node.isMesh) {
        node.castShadow = true;
        node.receiveShadow = true;
      }
    });

    if (index === 0) {
      setObj(group);
    }
  };

  useEffect(() => {
    loadFbx(model, 0);
  }, []);

  return (
    <mesh ref={ref} scale={0.12} position={[0, -1.75, 0]} receiveShadow>
      <meshStandardMaterial />
      <shadowMaterial attach="material" opacity={1} color="black" />

      {obj && (
        <primitive
          ref={ref2}
          object={obj}
          scale={1}
          position={[-15, 0, 0]}
          rotation={[MathUtils.degToRad(90), 0, 0]}
        />
      )}
    </mesh>
  );
}

export default function FbxLogo() {
  const ref = useRef();
  const [itemIndex, setItemIndex] = useState(1);

  return (
    <div>
      <Canvas
        ref={ref}
        camera={{
          position: [0, 0, 20],
          fov: 10,
        }}
        shadows
        style={{
          height: 150,
          width: "100wh",
          marginBottom: 32,
        }}
      >
        <directionalLight position={[0, 2, -5]} castShadow intensity={6} />

        <ambientLight intensity={1.5} />
        <pointLight position={[4, 0, 4]} castShadow />
        <Box itemIndex={itemIndex} setItemIndex={setItemIndex} />
        <OrbitControls
          minPolarAngle={(Math.PI - 0.05) / 2}
          maxPolarAngle={(Math.PI - 0.05) / 2}
          enableZoom={false}
          enablePan={false}
        />
        <ambientLight intensity={1} />
      </Canvas>
    </div>
  );
}
