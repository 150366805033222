import { useContext, useEffect, useRef, useState } from "react";
import Drawer from "react-modern-drawer";
import { Link, useLocation } from "react-router-dom";
import useWindowDimensions from "hooks/useWindowDimensions";
import { smallScreensWidth } from "consts";
import clsx from "clsx";
import "react-modern-drawer/dist/index.css";

import MenuSvg from "../assets/icons/menu.svg";
import { NavigationHeader } from "Header/NavigationHeader";
import { isAndroid, isIOS } from "react-device-detect";
import { HomeContext } from "contexts";
import LanguageSelector from "i18n/LanguageSelector";
import { navBar } from "RouteWithNavBar";
import { useTranslation } from "react-i18next";

export const Header = () => {
  const { t } = useTranslation();

  const { width } = useWindowDimensions();
  const { changeIsSomeMenuActive } = useContext(HomeContext);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const isSmallHeader = isIOS || isAndroid;

  const toggleDrawer = () => {
    setIsOpen(prevState => !prevState);
  };

  useEffect(() => {
    changeIsSomeMenuActive(isOpen);
  }, [isOpen]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setIsOpen(false);
  }, [location]);

  let lastScrollTop = 0;
  const headerRef = useRef<HTMLElement>(null);
  const headerbackgroundRef = useRef<HTMLDivElement>(null);

  const onScroll = () => {
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    const stickyMobile = document.getElementById("sticky");

    if (
      headerbackgroundRef.current &&
      headerRef.current &&
      isSmallHeader &&
      stickyMobile
    ) {
      if (scrollTop > lastScrollTop) {
        // Scrolling down
        headerRef.current.style.transform = "translateY(-64px)"; // Hide the menu
        headerbackgroundRef.current.style.transform = "translateY(-64px)";
        stickyMobile.style.top = "64px";
      } else {
        // Scrolling up
        headerRef.current.style.transform = "translateY(0)"; // Show the menu
        headerbackgroundRef.current.style.transform = "translateY(0)";
        stickyMobile.style.top = "128px";
      }
    }

    lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // For Mobile or negative scrolling
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [onScroll]);

  return (
    <>
      <header ref={headerRef}>
        <div className="nav-wrapper">
          <nav className="nav">
            <Link to="/">
              <img
                src={require("../assets/icons/logo.jpg")}
                alt="logo svg"
                width={40}
                height={40}
              />
            </Link>
            {width > smallScreensWidth ? (
              <>
                {navBar.map(item => (
                  <Link
                    key={item.path}
                    to={item.path}
                    className={clsx(
                      {
                        active: item.path === location.pathname,
                      },
                      "nav-link",
                    )}
                  >
                    {t(item.label)}
                  </Link>
                ))}
                <div />
                <div />
                <LanguageSelector />
              </>
            ) : (
              <div className="pointer" onClick={toggleDrawer}>
                <img src={MenuSvg} alt="menu" width={28} height={28} />
              </div>
            )}
          </nav>
        </div>
        <NavigationHeader />
      </header>
      <div className="nav-secondary-background" ref={headerbackgroundRef} />
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="top"
        className="drawer"
      >
        <div className="drawer-background">
          <LanguageSelector
            className="drawer-item drawer-item-center"
            onPress={toggleDrawer}
          />
          {navBar.map(item => (
            <Link
              key={item.path}
              to={item.path}
              className={clsx(
                {
                  active: item.path === location.pathname,
                },
                "nav-link",
                "drawer-item",
                "drawer-item-left",
              )}
              style={{
                zIndex: 1000,
              }}
            >
              {t(item.label)}
            </Link>
          ))}
        </div>
      </Drawer>
    </>
  );
};
