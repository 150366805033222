export const htmlGe = `
  <style type="text/css">
    body {
      background-color: white;
      min-width: 100%;
    }
    #zero-md {
      max-width: 700px;margin: 0 auto !important;
    }
  </style>
  <div style="margin-left: 16px; margin-right: 16px; margin-bottom: 35vh;">
    <zero-md 
      id="zero-md"
      src="/assets/დიზაინის_წესები.md">
    </zero-md>
  </div>
`;

export const htmlEn = `
  <style type="text/css">
    body {
      background-color: white;
      min-width: 100%;
    }
    #zero-md {
      max-width: 700px;margin: 0 auto !important;
    }
  </style>
  <div style="margin-left: 16px; margin-right: 16px; margin-bottom: 35vh;">
    <zero-md 
      id="zero-md"
      src="/assets/design_rules.md">
    </zero-md>
  </div>
`;
