import "./styles.scss";
import Quotes1Svg from "./assets/quotes1.svg";
import Quotes2Svg from "./assets/quotes2.svg";
import useIsVisible from "hooks/useIsVisible";
import { useTranslation } from "react-i18next";

function Section3() {
  const { t } = useTranslation();
  const { elementRef } = useIsVisible("History");
  return (
    <div className="third-section">
      <div className="mw1400 w100">
        <p
          className="medium32-text color-black border-bottom mb32"
          id="History"
          ref={elementRef}
        >
          {t("Our Story")}
          <span className="color-grey">{t(". A Common Experience")}</span>
        </p>
        <p className="regular28-text mb64">
          {t(
            "Our journey began with a familiar problem — a forgotten fine, which grew significantly due to delays. This “costly oversight,” which happened to our founder’s father (also our first investor), sparked the idea of creating the Borbalo app.",
          )}
        </p>
        <p className="medium32-text color-black border-bottom mb32">
          {t("Origin of the Name")}
          <span className="color-grey">{t(". From the Georgian Core")}</span>
        </p>
        <div className="row-column gap64 ai-center">
          <div className="mw400 flex1 section3-text-wrapper column">
            <div className="bg-white p32">
              <img
                src={Quotes1Svg}
                width={36}
                height={34}
                alt="quotes1"
                className="mb16"
              />
              <p className="regular24-text">
                {t("„Descended")} <strong>{t("On Borbalo")}</strong>{" "}
                {t("The moon of freedom…“")}
              </p>
            </div>
            <div className="p32 row jc-space-between ai-flex-end">
              <div>
                <p className="regular24-text mb8">
                  <strong>{t("Vazha Pshavela")}</strong>
                  <span className="color-grey"> {t("1892 წ.")}</span>
                </p>
                <p className="regular18-text">{t("Excerpt from Bakhtrioni")}</p>
              </div>
              <img src={Quotes2Svg} width={36} height={34} alt="quotes2" />
            </div>
          </div>
          <p className="regular28-text flex1">
            {t(
              "The name Borbalo draws from the epic work of Vazha Pshavela and the Georgian word Borbalo.",
            )}
            <br />
            <br />
            {t(
              "This fusion honors our rich cultural heritage and symbolizes our mission to overcome challenges with innovative solutions.",
            )}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Section3;
