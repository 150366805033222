import { useState } from "react";
import i18n from ".";
import LanguageSvg from "../assets/icons/language.svg";

const LanguageSelector = ({
  className,
  onPress,
}: {
  className?: string;
  onPress?: () => void;
}) => {
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language); // i18n.language contains the language assigned to lng in i18n.js file.

  const toggleLanguage = () => {
    const newLanguage = selectedLanguage === "en" ? "ge" : "en";
    onPress?.();
    i18n.changeLanguage(newLanguage);
    setSelectedLanguage(newLanguage);
  };

  return (
    <div className={className} onClick={toggleLanguage}>
      <img
        src={LanguageSvg}
        alt="language"
        width={27}
        height={29}
        style={{
          opacity: 0.5,
        }}
      />
    </div>
  );
};

export default LanguageSelector;
