export const cards1 = [
  {
    icon: require("./assets/cards1/icon1.svg").default,
    backgroundIcon: require("./assets/cards1/bg1.png"),
    backgroundIconColor: "#fff",
    title: "Reminders",
    description:
      "Borbalo sends notifications before discounts expire and fines increase.",
    buttonText: "Open",
    isButtonBlack: true,
    footerIcon: require("./assets/ok.svg").default,
    footerTitle: "Standard",
    footerText: "Borbalo 's Standard",
    textColor: "#000",
    textFooterColor: "#000",
  },
  {
    icon: require("./assets/cards1/icon2.svg").default,
    backgroundIcon: require("./assets/cards1/bg2.png"),
    backgroundIconColor: "#fff",
    title: "Information",
    description:
      "Learn more about your fine in Borbalo. For example, see the speed limit and your recorded speed.",
    buttonText: "Open",
    isButtonBlack: true,
    footerIcon: require("./assets/star.svg").default,
    footerTitle: "Full",
    footerText: "Exclusive to the Full Plan",
    textColor: "#000",
    textFooterColor: "#000",
  },
  {
    icon: require("./assets/cards1/icon3.svg").default,
    backgroundIcon: require("./assets/cards1/bg3.png"),
    backgroundIconColor: "#00C6BE",
    title: "Payment",
    description:
      "Easily pay multiple fines with your saved card through Borbalo.",
    buttonText: "Open",
    isButtonBlack: true,
    footerIcon: require("./assets/ok.svg").default,
    footerTitle: "Standard",
    footerText: "Borbalo 's Standard",
    textColor: "#000",
    textFooterColor: "#000",
  },
  {
    icon: require("./assets/cards1/icon4.svg").default,
    backgroundIcon: require("./assets/cards1/bg4.png"),
    backgroundIconColor: "rgba(0,0,0,.46)",
    title: "Non-Urgent Fines",
    titleFontSize: 22,
    description:
      "Borbalo identifies fines that aren’t immediately payable* and marks them as “non-urgent.”",
    buttonText: "Open",
    isButtonBlack: true,
    footerIcon: require("./assets/rudder.svg").default,
    footerTitle: "Basic",
    footerText: "Borbalo’s Standard Service",
    textColor: "#000",
    textFooterColor: "#000",
  },
  {
    icon: require("./assets/cards1/icon5.svg").default,
    backgroundIcon: require("./assets/cards1/bg5.svg").default,
    backgroundIconColor: "#fff",
    title: "Fine Location",
    titleFontSize: 23,
    description:
      "Only in Borbalo can you see the exact location of your fine on a map. Easily recall your violation.",
    buttonText: "Open",
    isButtonBlack: true,
    footerIcon: require("./assets/ok.svg").default,
    footerTitle: "Exclusive",
    footerText: "Only in Borbalo",
    textColor: "#000",
    textFooterColor: "#000",
  },
  {
    icon: require("./assets/cards1/icon6.svg").default,
    backgroundIcon: require("./assets/cards1/bg6.png"),
    backgroundIconColor: "#fff",
    title: "Summary",
    description:
      "On the car page, view how much you spend on fines monthly and annually in GEL.",
    buttonText: "Open",
    isButtonBlack: true,
    footerIcon: require("./assets/ok2.svg").default,
    footerTitle: "Standard",
    footerText: "Borbalo 's Standard",
    textColor: "#000",
    textFooterColor: "#000",
  },
  {
    icon: require("./assets/cards1/icon7.svg").default,
    backgroundIcon: require("./assets/cards1/bg7.png"),
    backgroundIconColor: "#00C6BE",
    title: "Guarantee",
    description:
      "Fines paid through Borbalo come with a 100% repayment guarantee. The payment is instantly transferred to the relevant agency.",
    buttonText: "Open",
    isButtonBlack: true,
    footerIcon: require("./assets/ok.svg").default,
    footerTitle: "Standard",
    footerText: "Borbalo 's Standard",
    textColor: "#000",
    textFooterColor: "#000",
  },
];

export const cards2 = [
  {
    icon: require("./assets/cards2/icon1.svg").default,
    backgroundIcon: require("./assets/cards2/bg1.png"),
    backgroundIconColor: "#fff",
    title: "Towing Alerts",
    titleFontSize: 28,
    description:
      "In Tbilisi, Borbalo notifies you if your car is marked by a tow truck and destined for a penalty parking lot.",
    buttonText: "Open",
    isButtonBlack: true,
    footerIcon: require("./assets/star.svg").default,
    footerTitle: "Full",
    footerText: "Exclusive to the Full Plan",
    textColor: "#000",
    textFooterColor: "#000",
  },
  {
    icon: require("./assets/cards2/icon2.svg").default,
    backgroundIcon: require("./assets/cards2/bg2.png"),
    backgroundIconColor: "#fff",
    title: "Fuel",
    description:
      "Get daily updates on fuel prices across the country and make informed choices.",
    buttonText: "Open",
    isButtonBlack: true,
    footerIcon: require("./assets/ok.svg").default,
    footerTitle: "Basic",
    footerText: "Borbalo’s Standard Service",
    textColor: "#000",
    textFooterColor: "#000",
  },
  {
    icon: require("./assets/cards2/icon3.svg").default,
    backgroundIcon: require("./assets/cards2/bg3.svg").default,
    backgroundIconColor: "#fff",
    title: "Technical Inspection",
    titleFontSize: 25,
    description:
      "Borbalo reminds you of your vehicle’s mandatory periodic technical inspection. ",
    buttonText: "Open",
    isButtonBlack: true,
    footerIcon: require("./assets/rudder.svg").default,
    footerTitle: "Basic",
    footerText: "Borbalo’s Standard Service",
    textColor: "#000",
    textFooterColor: "#000",
  },
  {
    icon: require("./assets/cards2/icon4.svg").default,
    backgroundIcon: require("./assets/cards2/bg4.png"),
    backgroundIconColor: "#00C6BE",
    title: "Automatic Payments",
    titleFontSize: 21,
    description:
      "Automatic payments ensure fines are paid at the optimal time for you — set it up and forget the stress.",
    buttonText: "Open",
    isButtonBlack: true,
    footerIcon: require("./assets/plus-white.svg").default,
    footerTitle: "Additional",
    footerText: "Available for an Extra Fee",
    textColor: "#fff",
    textFooterColor: "#fff",
  },
  {
    icon: require("./assets/cards2/icon5.svg").default,
    backgroundIcon: require("./assets/cards2/bg5.svg").default,
    backgroundIconColor: "#fff",
    title: "Towing Alerts",
    titleFontSize: 20,
    description:
      "In Tbilisi, Borbalo notifies you if your car is marked by a tow truck and destined for a penalty parking lot.",
    buttonText: "Open",
    isButtonBlack: true,
    footerIcon: require("./assets/plus.svg").default,
    footerTitle: "Additional",
    footerText: "Available for an Extra Fee",
    textColor: "#000",
    textFooterColor: "#000",
  },
  {
    icon: require("./assets/cards2/icon6.svg").default,
    backgroundIcon: require("./assets/cards2/bg6.png"),
    backgroundIconColor: "#fff",
    title: "Parking Permits",
    titleFontSize: 28,
    description:
      "Purchase parking permits effortlessly with Borbalo. Available in most Georgian cities!",
    buttonText: "Open",
    isButtonBlack: true,
    footerIcon: require("./assets/dots.svg").default,
    footerTitle: "Services",
    footerText: "Offered Services",
    textColor: "#000",
    textFooterColor: "#000",
  },
];

export const cards3 = [
  {
    icon: require("./assets/cards3/icon1.svg").default,
    backgroundIcon: require("./assets/cards3/bg1.svg").default,
    backgroundIconColor: "#fff",
    title: "Free Version",
    description:
      "Easily access police and agency websites and pay fines with a saved card.",
    buttonText: "Open",
    isButtonBlack: true,
    footerIcon: require("./assets/ok.svg").default,
    footerTitle: "Standard",
    footerText: "Borbalo 's Standard",
    textColor: "#000",
    textFooterColor: "#000",
  },
  // {
  //   icon: require("./assets/cards3/icon2.svg").default,
  //   backgroundIcon: require("./assets/cards3/bg2.svg").default,
  //   backgroundIconColor: "#fff",
  //   title: "CarPlay",
  //   titleFontSize: 29,
  //   description:
  //     "Borbalo integrates with CarPlay. Track traffic cameras right from your car’s display.",
  //   buttonText: "Open",
  //   isButtonBlack: true,
  //   footerIcon: require("./assets/ok-white.svg").default,
  //   footerTitle: "Standard",
  //   footerText: "Borbalo 's Standard",
  //   textColor: "#000",
  //   textFooterColor: "#fff",
  // },
  {
    icon: require("./assets/cards3/icon3.svg").default,
    backgroundIcon: require("./assets/cards3/bg3.svg").default,
    backgroundIconColor: "#FFBA00",
    title: "Legal Explanations",
    titleFontSize: 22,
    description:
      "Borbalo’s legal team clarifies every fine article so you can understand why you were fined.",
    buttonText: "Open",
    isButtonBlack: true,
    footerIcon: require("./assets/ok.svg").default,
    footerTitle: "Standard",
    footerText: "Borbalo 's Standard",
    textColor: "#000",
    textFooterColor: "#000",
  },
  {
    icon: require("./assets/cards3/icon4.svg").default,
    backgroundIcon: require("./assets/cards3/bg4.png"),
    backgroundIconColor: "#000",
    title: "Your 3D Car",
    titleFontSize: 27,
    description:
      "Scan your Techpassport to view a 3D model of your car — color and age model included.",
    buttonText: "Open",
    isButtonBlack: false,
    footerIcon: require("./assets/ok-white.svg").default,
    footerTitle: "Standard",
    footerText: "Borbalo 's Standard",
    textColor: "#fff",
    textFooterColor: "#fff",
  },
  {
    icon: require("./assets/cards3/icon5.svg").default,
    backgroundIcon: require("./assets/cards3/bg5.png"),
    backgroundIconColor: "#000",
    title: "Contribute",
    titleFontSize: 29,
    description:
      "Help develop Borbalo’s Map. For instace if you report a traffic camera which is not in our database.",
    buttonText: "Open",
    isButtonBlack: true,
    footerIcon: require("./assets/ok.svg").default,
    footerTitle: "Standard",
    footerText: "Borbalo 's Standard",
    textColor: "#000",
    textFooterColor: "#000",
  },
  {
    icon: require("./assets/cards3/icon6.svg").default,
    backgroundIcon: require("./assets/cards3/bg6.png"),
    backgroundIconColor: "#00C6BE",
    title: "Landmarks",
    titleFontSize: 25,
    description:
      "Borbalo crafts a beautiful map for you. Our 3D city landmarks help you navigate with ease. ",
    buttonText: "Open",
    isButtonBlack: false,
    footerIcon: require("./assets/ok-white.svg").default,
    footerTitle: "Standard",
    footerText: "Borbalo 's Standard",
    textColor: "#fff",
    textFooterColor: "#fff",
  },
  {
    icon: require("./assets/cards3/icon7.svg").default,
    backgroundIcon: require("./assets/cards3/bg7.png"),
    backgroundIconColor: "#fff",
    title: "Easy Car Addition",
    titleFontSize: 27,
    description:
      "Adding a car to Borbalo is simple: scan your Techpassport, and our system auto-fills your data.",
    buttonText: "Open",
    isButtonBlack: true,
    footerIcon: require("./assets/ok.svg").default,
    footerTitle: "Standard",
    footerText: "Borbalo 's Standard",
    textColor: "#000",
    textFooterColor: "#000",
  },
  {
    icon: require("./assets/cards3/icon8.svg").default,
    backgroundIcon: require("./assets/cards3/bg8.png"),
    backgroundIconColor: "#fff",
    title: "Translation",
    description:
      "Borbalo is translated into English, making it accessible for non-Georgian-speaking drivers.",
    buttonText: "Open",
    isButtonBlack: true,
    footerIcon: require("./assets/ok.svg").default,
    footerTitle: "Standard",
    footerText: "Borbalo 's Standard",
    textColor: "#000",
    textFooterColor: "#000",
  },
  {
    icon: require("./assets/cards3/icon9.svg").default,
    backgroundIcon: require("./assets/cards3/bg9.png"),
    backgroundIconColor: "#fff",
    title: "Dark Mode",
    description:
      "Borbalo offers an elegant dark mode, enhancing your experience with its calming design.",
    buttonText: "Open",
    isButtonBlack: false,
    footerIcon: require("./assets/ok-white.svg").default,
    footerTitle: "Standard",
    footerText: "Borbalo 's Standard",
    textColor: "#fff",
    textFooterColor: "#fff",
  },
  {
    icon: require("./assets/cards3/icon10.svg").default,
    backgroundIcon: require("./assets/cards3/bg10.svg").default,
    backgroundIconColor: "#fff",
    title: "Archive",
    description:
      "All transactions are securely stored in the archive so you can access them anytime.",
    buttonText: "Open",
    isButtonBlack: true,
    footerIcon: require("./assets/ok.svg").default,
    footerTitle: "Standard",
    footerText: "Borbalo 's Standard",
    textColor: "#000",
    textFooterColor: "#000",
  },
];

export const cards4 = [
  {
    icon: require("./assets/cards4/icon1.svg").default,
    backgroundIcon: require("./assets/cards4/bg1.png"),
    backgroundIconColor: "#fff",
    title: "Ministry of Internal Affairs",
    description:
      "Our Primary Partner the Ministry of Internal Affairs of Georgia. View and pay fines issued by the Ministry.",
    buttonText: "Open",
    isButtonBlack: true,
    footerIcon: require("./assets/rudder.svg").default,
    footerTitle: "Basic",
    footerText: "without extra charge",
    textColor: "#000",
    textFooterColor: "#000",
  },
  {
    icon: require("./assets/cards4/icon2.svg").default,
    backgroundIcon: require("./assets/cards4/bg2.png"),
    backgroundIconColor: "#fff",
    title: "Tbilisi",
    description:
      "For Capital City Residents monitor fines and receive notifications if your car is flagged for towing.",
    buttonText: "Open",
    isButtonBlack: false,
    footerIcon: require("./assets/plus-white.svg").default,
    footerTitle: "Additional",
    footerText: "Available for an Extra Fee",
    textColor: "#fff",
    textFooterColor: "#fff",
  },
  {
    icon: require("./assets/cards4/icon3.svg").default,
    backgroundIcon: require("./assets/cards4/bg3.png"),
    backgroundIconColor: "#fff",
    title: "Kutaisi",
    description:
      "Once upon a time, there was anything better than a Lord, there once was a singing blackbird, and Lord our merciful. Disaster there, a feast here.",
    buttonText: "Open",
    isButtonBlack: false,
    footerIcon: require("./assets/rudder-white.svg").default,
    footerTitle: "Basic",
    footerText: "Borbalo’s Standard Service",
    textColor: "#fff",
    textFooterColor: "#fff",
  },
  {
    icon: require("./assets/cards4/icon4.svg").default,
    backgroundIcon: require("./assets/cards4/bg4.png"),
    backgroundIconColor: "#fff",
    title: "Gori",
    titleFontSize: 32,
    description:
      "Once upon a time, there was anything better than a Lord, there once was a singing blackbird, and Lord our merciful. Disaster there, a feast here.",
    buttonText: "Open",
    isButtonBlack: false,
    footerIcon: require("./assets/rudder-white.svg").default,
    footerTitle: "Basic",
    footerText: "Borbalo’s Standard Service",
    textColor: "#fff",
    textFooterColor: "#fff",
  },
  {
    icon: require("./assets/cards4/icon5.svg").default,
    backgroundIcon: require("./assets/cards4/bg5.png"),
    backgroundIconColor: "#fff",
    title: "UCC",
    smallTitle: "204558353",
    titleFontSize: 32,
    description: "tssxx",
    buttonText: "Open",
    isButtonBlack: false,
    footerIcon: require("./assets/rudder-white.svg").default,
    footerTitle: "Partner",
    footerText: "Borbalo’s Partner",
    textColor: "#fff",
    textFooterColor: "#fff",
  },
];
