import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationsInEng from "./locales/en.json";
import translationsInGerman from "./locales/ge.json";

// the translations
const resources = {
  ge: {
    translation: translationsInGerman,
  },
  en: {
    translation: translationsInEng,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "ge",
  debug: true,
  fallbackLng: "ge",
  interpolation: {
    escapeValue: false,
  },
  ns: "translation",
  defaultNS: "translation",
});

export default i18n;
