import Qr from "assets/icons/qr.svg";
import { isAndroid, isIOS } from "react-device-detect";
import { useTranslation } from "react-i18next";

const downloadBorbaloDeeplink = "#";

const QrComponent = ({ isMobileButton }: { isMobileButton: boolean }) => {
  const { t } = useTranslation();
  if (!isMobileButton && (isAndroid || isIOS)) {
    return null;
  }

  if (isAndroid || isIOS) {
    return (
      <a href={downloadBorbaloDeeplink}>
        <div className="qr-wrapper-button">
          <div className="bold19-text text-center w-full">
            {t("Borbalo's")}
            <br />
            {t("Download")}
          </div>
        </div>
      </a>
    );
  }
  return (
    <div className="qr-wrapper">
      <div className="bold19-text text-align-center w100">
        {t("Borbalo's")}
        <br />
        {t("Download")}
      </div>
      <img src={Qr} width="100px" height="100px" alt="qr-code" />
    </div>
  );
};

export default QrComponent;
