import "./styles.scss";
import { useTranslation } from "react-i18next";

function Section1() {
  const { t } = useTranslation();

  return (
    <>
      <div className="first-section mb64">
        <div className="mw1400 w100">
          <img
            src={require("./assets/section1-logos.png")}
            width="294px"
            height="128px"
            alt="right"
            className="mb64"
          />
          <div className="row jc-space-between flex-wrap gap32">
            <p className="regular32-text color-white">
              {t("Creating Brilliance Georgian, Creating Brilliance")}
            </p>
            <p className="regular32-text color-white">{t("KJ Group")}</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Section1;
