import useIsVisible from "hooks/useIsVisible";
import "./styles.scss";
import { useTranslation } from "react-i18next";

function Section2() {
  const { t } = useTranslation();
  const { elementRef } = useIsVisible("Team");
  return (
    <div className="second-section">
      <div className="row-column jc-space-between gap32 mb32 border-bottom w100 mw1400">
        <p className="medium32-text color-black" id="Team" ref={elementRef}>
          {t("Our Team")}
          <span className="color-grey">
            {t(". Spread across the globe, together – Borbalo")}
          </span>
        </p>
        <p className="medium32-text color-black mw400">{t("Founder")}</p>
      </div>
      <div className="row ai-center jc-space-between gap32 mw1400">
        <div className="flex1">
          <img
            src={require("./assets/map.png")}
            width="100%"
            alt="right"
            style={{
              objectFit: "contain",
            }}
          />
        </div>
        <div className="column mw400 section2-wrapper">
          <p className="meduim18-text mb8">{t("Founder & CEO")}</p>
          <p className="medium32-text">{t("Koba Jgenti")}</p>
        </div>
      </div>
    </div>
  );
}

export default Section2;
