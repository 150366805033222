import { usePrivacyPolicy } from "hooks/usePrivacyPolicy";

function PrivacyPolicy() {
  usePrivacyPolicy();

  return (
    <div
      // @ts-ignore
      name="termly-embed"
      data-id="30abccfb-9b2c-47b8-9052-949e0f105d17"
    ></div>
  );
}

export default PrivacyPolicy;
