import { useGenericPartnersPrivacyPolicy } from "hooks/useGenericPartnersPrivacyPolicy";

function GenericPartnersPrivacyPolicy() {
  useGenericPartnersPrivacyPolicy();

  return (
    <div
      // @ts-ignore
      name="termly-embed"
      data-id="085de924-41a7-4e93-b943-926598035952"
    ></div>
  );
}

export default GenericPartnersPrivacyPolicy;
