import { createContext } from "react";

export type HomeContextType = {
  activeId: string;
  changeActiveId: (index: string) => void;
  isSomeMenuActive: boolean;
  changeIsSomeMenuActive: (value: boolean) => void;
};

export const HomeContext = createContext<HomeContextType>({
  changeActiveId: () => {},
  activeId: "",
  isSomeMenuActive: false,
  changeIsSomeMenuActive: () => {},
});
