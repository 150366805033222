import { usePrivacyPolicy } from "hooks/usePrivacyPolicy";

function TermsOfUse() {
  usePrivacyPolicy();

  return (
    <div
      // @ts-ignore
      name="termly-embed"
      data-id="58af882a-65e2-47d0-a809-12591d7f1b91"
    ></div>
  );
}

export default TermsOfUse;
