import { useEffect } from "react";
import { htmlEn, htmlGe } from "./html";

function Guideline({ isEn }: { isEn: boolean }) {
  useEffect(() => {
    const script = document.createElement("script");

    script.src =
      "https://cdn.jsdelivr.net/gh/zerodevx/zero-md@2/dist/zero-md.min.js";
    script.type = "module";
    script.async = false;

    document.head.appendChild(script);
  }, []);

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: isEn ? htmlEn : htmlGe,
      }}
    ></div>
  );
}

export default Guideline;
